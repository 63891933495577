import React from "react";
import { Link } from "gatsby";
// @ts-ignore
import AppStore from "../assets/app-store.svg";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Button from "@material-ui/core/Button";

const Download = () => {
  const [isVideoShowing, setIsVideoShowing] = React.useState(false);
  function showVideo() {
    setIsVideoShowing(true);
  }

  return (
    <Layout>
      <SEO title="Download PanoRoom" description="Get PanoRoom for your device!"/>
      <div
        className="blogpost"
        style={{ maxWidth: 600, margin: "0px auto", padding: "0px 1em" }}
      >
        <h1 style={{ textDecoration: "underline" }}>
          Start using PanoRoom in three steps:
        </h1>
        <h2>
          <a href="https://apps.apple.com/app/apple-store/id1489359406?pt=120014641&ct=download-page&mt=8">
            1. Download PanoRoom from App Store & open it right away.
            <div
              style={{
                width: "100%",
                display: "flex",
                padding: "1em",
                justifyContent: "center",
              }}
            >
              <img src={AppStore} />
            </div>
          </a>
        </h2>
        <h2 style={{ marginBottom: "1em" }}>
          2. If you want to make measurements like a pro, watch this 3-minute
          tutorial:
          {!isVideoShowing && (
            <div
              style={{
                width: "100%",
                display: "flex",
                padding: "1em",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                onClick={showVideo}
                style={{
                  backgroundColor: "#243B55",
                  color: "white",
                  borderRadius: "1em",
                  fontWeight: "bold",
                }}
              >
                Watch the video
              </Button>
            </div>
          )}
          {isVideoShowing && (
            <div>
              <iframe
                width="100%"
                height="337.5"
                src="https://www.youtube.com/embed/G99L8EatdCM?autoplay=1"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ padding: "0.5em 0em" }}
              ></iframe>
            </div>
          )}
        </h2>
        <h2 style={{ marginBottom: "1em" }}>
          <a href="/assets/panoroom-sample-plans.zip" download>
            3. Get your first clients by posting marketing materials on your
            website. It's exceptionally easy with our marketing pack.
            <div
              style={{
                width: "100%",
                display: "flex",
                padding: "1em",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#243B55",
                  color: "white",
                  borderRadius: "1em",
                  fontWeight: "bold",
                }}
              >
                Download
              </Button>
            </div>
          </a>
        </h2>
      </div>
    </Layout>
  );
};

export default Download;
